import { ContentData } from '@innedit/innedit';
import { ContentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../../../components/View';
import Form from '../../../../../../../../containers/Espace/Form';
import params from '../../../../../../../../params/produit/content.json';
import TemplateEspace from '../../../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../../../utils/requireUser';

const PageEspaceCollectionDocumentContent: FC<
  PageProps & UserProps & EspaceProps
> = ({
  espace,
  user,
  params: { collectionId, contentId, documentId, espaceId },
}) => {
  const contentData = new ContentData({
    espaceId,
    params,
    parentCollectionName: `collections/${collectionId}/documents`,
    parentId: documentId,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form<ContentType>
          displayAdd={false}
          displayNavigation={false}
          displayView={false}
          docId={contentId}
          itemPathnamePrefix={`/espaces/${espaceId}/collections/${collectionId}/documents/${documentId}/content/`}
          itemPathnameSuffix=""
          model={contentData}
          name="content"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollectionDocumentContent);
